import endpoints from '@/config/api-endpoints'

// не использовать
// использовать только автосгенеренные ручки

export const userMethods = ctx => {

  class UserMethods  {
    async getAlluserSettings(params = {}) {
      return await ctx.useApi?.request(params)
    }
    async getUsers(sortTable) {
      return await ctx.$services.useAPI.request({ config: endpoints.account.users(sortTable) })
    }
  }

  return new UserMethods()
}
